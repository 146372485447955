/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// require("prismjs/themes/prism.css")
require('typeface-lato')
require('prismjs/themes/prism-okaidia.css')
require('@fortawesome/fontawesome-free/css/all.css')


